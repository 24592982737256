/** @jsx jsx */
import React, { useCallback, useState } from 'react'
import { jsx, Heading, Box, Input, Label, Button, Flex, Alert, Text } from 'theme-ui';
import Layout from '../components/layout';
import FullScreen from '../components/layout/fullScreen'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import Logo from '../images/fgr-logo.png'
import { ToolTip } from '../components/form'
import { Powered } from '../components/footer'
import { forgotPassword, forgotPasswordSubmit, isLoggedIn } from "../utils/auth"
import update from 'immutability-helper';
import { navigate, Link } from 'gatsby';
import MetaData from '../components/metadata'

const PasswordReset = (props) => {

  const [state, setState] = useState({
    email: '',
  });
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [codeSent, setCodeSent] = useState()

  const innerSX = {
    mt: '91px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }

  const logoSX = {
    width: '138px',
    height: '138px',
    mb: 3
  }

  const formSX = {
    width: '300px',
    margin: '0 auto',
    textAlign: 'center'
  }

  const linkSX = {
    fontWeight: 700,
    textDecoration: 'underline',
    color: 'black',
    fontSize: 0,
    display: 'block'
  }

  const errorSX = {
    color: 'red',
    textAlign: 'left',
    pt: 2,
  }

  const handleSubmit = async (e) => {
    setError(false)
    e.preventDefault();
    forgotPassword(state.email).then((res) => {
      console.log('success', res);
      setCodeSent(true)
    }).catch((err) => {
      console.log('error', err);
      setError({
        field: 'global', 
        value: err?.message
      })
    });
  }

  const validateFields = () => {
    const required = [
      'password',
      'code',
    ]

    let errors = false

    required.forEach((x) => {
      if (!state[x] || state[x] === '') {
        if (!errors) {
          errors = []
        }
        errors.push({
          field: x,
          value: 'This field is required'
        })
        return true
      }
    })
    setError(errors)
    return errors
  }

  const Error = ({type}) => {
    if (!error || !Array.isArray(error))
      return null

    const found = error.find((x) => {
      return x.field === type
    })

    if (!found)
      return null

    return <Text sx={errorSX}>{found.value}</Text>
  }

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setError(false)

    const validationErrors = validateFields()
    if (validationErrors)
      return

    forgotPasswordSubmit(state.email, state.code.trim(), state.password).then((res) => {
      setSuccess(true)
    }).catch((err) => {
      console.log('error', err);
      setError({
        field: 'global', 
        value: err?.message
      })
    });
  }

  const handleChange = (e) => {
    setState(update(state, {
      [e.target.name]: {
        $set: e.target.value
      }
    }))
  }

  if (isLoggedIn()) {
    navigate('/')
    return null
  }

  return (
    <Layout {...props} plain>
      <MetaData title="Reset Password" />
      <FullScreen backgroundColor="background">
        <Wrapper>
          <Inner sx={innerSX}>
            <Heading variant="headingsmall" as="h1" mb={12}>My FGR</Heading>
            <img src={Logo} alt="FGR" sx={logoSX} />
            <Heading variant="headingsmall" as="h2" mb={12}>Reset Password</Heading>
            <Box as="form" sx={formSX}>
              {error?.field === 'global' &&
                <Alert variant='error' mb={2}>{error?.value}</Alert>
              }
              {success &&
                <Alert variant='primary' mb={2}><p>Your password has been successfully reset</p></Alert>
              }
              {codeSent && !success &&
                <React.Fragment>
                  <Box mb={6}>
                    <Flex sx={{justifyContent: 'space-between'}} mb={2}>
                      <Label>6-digit code that was emailed to you</Label>
                      <ToolTip>We just sent you an email with a code.</ToolTip>
                    </Flex>
                    <Input type="text" name="code" value={state.code} onChange={handleChange} />
                    <Error type="code" />
                  </Box>
                  <Box mb={6}>
                    <Flex sx={{justifyContent: 'space-between'}} mb={2}>
                      <Label>New password</Label>
                    </Flex>
                    <Input type="password" name="password" value={state.password} onChange={handleChange} />
                    <Error type="password" />
                  </Box>
                  <Button variant="transparent" mb={4} onClick={handleCodeSubmit}>Submit</Button>
                </React.Fragment>
              }
              {!codeSent &&
                <React.Fragment>
                  <Flex sx={{justifyContent: 'space-between'}} mb={2}>
                    <Label>Email address</Label>
                    <ToolTip>The email address you registered with</ToolTip>
                  </Flex>
                  <Input type="email" name="email" autoComplete="username" mb={6} value={state.email} onChange={handleChange} />
                  <Button variant="transparent" mb={4} onClick={handleSubmit}>Reset</Button>
                </React.Fragment>
              }
              <Text sx={linkSX} as={Link} to='/login'>Back to login</Text>
            </Box>
            <Powered />
          </Inner>
        </Wrapper>
      </FullScreen>
    </Layout>
  )
}

export default PasswordReset